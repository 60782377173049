import React                                            from 'react';
import { BrowserRouter as Router, Switch, Route }       from 'react-router-dom';
import { useUser }                                      from 'reactfire';
import { AuthProvider }                                 from './store/Auth';
import PrivateRoute                                     from './store/PrivateRoute';

// import NavBar                                           from './components/partials/NavBar';

import EmailVerificationView                            from './components/SiteManagement/EmailVerificationView';
import EmailNotVerifiedView                             from './components/SiteManagement/EmailNotVerifiedView';
import SiteManagementView                               from './components/SiteManagement/SiteManagementView';

import NotFound404                                      from './components/NotFound404';
import HomeView                                         from './components/HomeView';
import ProfileView                                      from './components/ProfileView';
// import PortfolioView                                    from './components/PortfolioView';
import Portfolio                                        from './components/portfolio/index';

function App() {
  const currentUser = useUser();

  return (
    <AuthProvider >
      <Router>
        {/* <NavBar /> */}
        <Switch>
          <Route exact path="/" component={HomeView} />
          <Route exact path="/profile" component={ProfileView} />
          {/*<Route exact path="/portfolio" component={PortfolioView} />*/}
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/emailverification" component={EmailVerificationView} />
          <Route exact path="/emailnotverified" component={EmailNotVerifiedView} />
          {
            currentUser && <PrivateRoute exact path="/sitemanagement" component={SiteManagementView} />
          }
          <Route path="*" component={NotFound404} />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
