import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import { Link } from "react-router-dom";
import {database} from "../store/base";
import SectionDivider1 from './partials/SectionDivider1';
import Layout from './partials/Layout';
import refreshIcon from '../assets/refresh-icon.svg';
import HomePageScrollToLinks from './partials/HomePageScrollToLinks';

function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const Slider = ( props ) => {
  const [curr, setCurr] = useState(0);
  // const [showFullQuote, setShowFullQuote] = useState(false);
  // console.log('props', props);
  let slides = props.homeObjects.whatPeopleThink;

  const goToNext = () => {
    if (slides) {
      setCurr(curr === slides.length - 1 ? 0 : curr + 1);
    }
  }

  const goToPrev = () => {
    let index = 0;
    slides.map((slide) => {
      index ++;
      if (index === curr) {
        setCurr(curr === slides.length - 1 ? 0 : curr - 1);
        clearTimeout(goToNext);
      }
      return null;
    })
  }

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {
    setTimeout(goToNext, 600000);

    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 1000);

    window.addEventListener('resize', debouncedHandleResize);

    return function() {
      window.removeEventListener('resize', debouncedHandleResize);
      clearTimeout(goToNext);
    }
  });

  const openFullQuote = () => {
    document.body.classList.add('noscroll');
    props.setShowFullQuote(true);
  }
  const closeFullQuote = () => {
    document.body.classList.remove('noscroll');
    props.setShowFullQuote(false);
  }

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  let MAX_LENGTH = 0;
  // let MAX_LENGTH = 140;
  dimensions.width < 600 ? MAX_LENGTH = 80 : MAX_LENGTH = 140;
  // console.log('MAX_LENGTH', MAX_LENGTH);
  // console.log('dimensions', dimensions);

  return (
    <div className="slider">
      {
        slides.map((s, i) => (
          <div className={i === curr ? "slide active" : "slide"} key={i} aria-hidden={i !== curr}>
            {
              !props.showFullQuote &&
              <div className="quote_holder">
                <h2>What people think</h2>
                <h3 dangerouslySetInnerHTML={{ __html: s.client }}></h3>
                <div className="slide_nav_container">
                  { curr !== 0 && <Link className="slide_nav prev" to="" onClick={goToPrev}><span className="symbole">&#x276E;</span></Link> }
                  <Link className="slide_nav next" to="" onClick={goToNext}><span className="symbole">&#x276F;</span></Link>
                </div>
                <div className="left_quote">&ldquo;</div>
                {
                  s.quote.length > MAX_LENGTH ?
                  (
                    <>
                      <p className="quote" dangerouslySetInnerHTML={{ __html: `${s.quote.substring(0, MAX_LENGTH)}...` }}></p>
                      <Link className="read_more_text" to="" onClick={openFullQuote}>&#60; read more &#62;</Link>
                    </>
                  )
                  :
                  <p className="quote" dangerouslySetInnerHTML={{ __html: s.quote }}></p>
                }
                <div className="right_quote">&rdquo;</div>
                <div className="quote_details">
                  <div>
                    <span>{s.quoteBy.firstName}&nbsp;</span>
                    <span>{s.quoteBy.lastName}</span>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: s.position }}></div>
                  <div dangerouslySetInnerHTML={{ __html: s.project }}></div>
                </div>
              </div>
            }

            {
              props.showFullQuote &&
              <div className="full_quote_container">
                <div className="full_quote">
                  <div className="close_full_quote" onClick={closeFullQuote}>&#10005;</div>
                  <h3>{s.client}</h3>

                  <div className="slide_nav_container">
                    { curr !== 0 && <Link className="slide_nav prev" to="" onClick={goToPrev}><span className="symbole">&#x276E;</span></Link> }
                    <Link className="slide_nav next" to="" onClick={goToNext}><span className="symbole">&#x276F;</span></Link>
                  </div>

                  <div className="left_quote">&ldquo;</div>
                  <p className="quote" dangerouslySetInnerHTML={{ __html: s.quote }}></p>
                  <div className="right_quote">&rdquo;</div>
                  <div className="quote_details">
                    <div>
                      <span>{s.quoteBy.firstName}&nbsp;</span>
                      <span>{s.quoteBy.lastName}</span>
                    </div>
                    <div>{s.position}</div>
                    <div>{s.project}</div>
                  </div>
                </div>
              </div>
            }
          </div>
        ))
      }
    </div>
  );
}

// const HomePageScrollToLinks = ( props ) => {
//   // console.log('props', props);
//
//   return (
//     <ul>
//       <li onClick={props.scrollToWelcome}>Welcome</li>
//       <li onClick={props.scrollToWhatIDo}>What I do</li>
//       <li onClick={props.scrollToWhatPeopleThink}>What people think</li>
//       <li onClick={props.scrollToAboutMe}>About me</li>
//       <li onClick={props.scrollToAboutSite}>About this site</li>
//     </ul>
//   )
// }

const HomeView = ( props ) => {
  const [pageTitle, setPageTitle] = useState('');
  const [homeObjects, setHomeObjects] = useState([]);
  const [showFullQuote, setShowFullQuote] = useState(false);

  useEffect(() => {
    database.ref('zhomePage').on('value', snapshot => { if (snapshot.val() !== null) { setHomeObjects({ ...snapshot.val() }); } });
    setPageTitle('UI Developer');
  }, [pageTitle]);

  // console.log('homeObjects.welcomeSection', homeObjects.welcomeSection);
  // console.log('homeObjects.whatIDo', homeObjects.whatIDo);
  // if (homeObjects.whatPeopleThink) console.log('homeObjects.whatPeopleThink', homeObjects.whatPeopleThink);
  // console.log('homeObjects.aboutCisco', homeObjects.aboutCisco);
  // console.log('homeObjects.aboutSite', homeObjects.aboutSite);

  // General scroll to element function
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const welcomeRef = useRef(null);
  const scrollToWelcome = () => scrollToRef(welcomeRef);

  const whatIDoRef = useRef(null);
  const scrollToWhatIDo = () => scrollToRef(whatIDoRef);

  const whatPeopleThinkRef = useRef(null);
  const scrollToWhatPeopleThink = () => scrollToRef(whatPeopleThinkRef);

  const aboutMeRef = useRef(null);
  const scrollToAboutMe = () => scrollToRef(aboutMeRef);

  const aboutSiteRef = useRef(null);
  const scrollToAboutSite = () => scrollToRef(aboutSiteRef);

  return (
    <Layout title={pageTitle} description="This is the Home page">

      <HomePageScrollToLinks {...({scrollToWelcome, scrollToWhatIDo, scrollToWhatPeopleThink, scrollToAboutMe, scrollToAboutSite})} />

      <div className={`sections_holder${showFullQuote ? " slider_showing_full_quote" : ""}`} ref={welcomeRef}>

        {/* welcome_screen */}
        <section className="section section_full_height centered_center white" ref={welcomeRef}>
          <div className="welcome_screen text_centered">
            <div className="welcome_logo_holder"><img src="https://firebasestorage.googleapis.com/v0/b/franciscobenedict-1aa7b.appspot.com/o/fb-logo-grey.svg?alt=media&token=905952fd-7bc7-4afc-a601-c47751890ac5" alt="" /></div>
            {
              homeObjects.welcomeSection ?
              <>
                {
                  homeObjects.welcomeSection.map((item, i) => (
                    <span key={i}>
                      <h1 className="page_title">{item.paragraphText1}</h1>
                      <ul className="inline_list center">
                        {
                          item.coreSkills.map((skill, j) => (
                            <li key={j} className="inline_list_item">{skill}{j !== item.coreSkills.length-1 ? <span className='bullet_point'>&nbsp;&bull;&nbsp;</span>:<span className='blinking-cursor'>|</span>}</li>
                          ))
                        }
                      </ul>
                    </span>
                  ))
                }
              </>
              :
              <div className="loading_element"><img src={refreshIcon} alt="" /></div>
            }
            <h2 className="welcome_text">Welcome</h2>
          </div>
          <div className="scroll_to_button" onClick={scrollToWhatIDo}></div>
        </section>

        <SectionDivider1 />

        {/* what_i_do_section */}
        <section className="section section_full_height centered_vertically super_light_grey" ref={whatIDoRef}>
          <div className="what_i_do_section section_container">
            {
              homeObjects.whatIDo ?
              <>
                {
                  homeObjects.whatIDo.map((item, i) => (
                    <span key={i}>
                      <h2 className="page_title">What I do</h2>
                      <p className="page_title">{item.whatIDoIntro}</p>

                      <h3>Web development</h3>
                      <p>{item.webDevelopment.intro}</p>
                      <h3>Graphic & UI design</h3>
                      <p>{item.graphicDesign.intro}</p>
                    </span>
                  ))
                }
              </>
              :
              <div className="loading_element"><img src={refreshIcon} alt="" /></div>
            }
          </div>
          <div className="scroll_to_button" onClick={scrollToWhatPeopleThink}></div>
        </section>

        <SectionDivider1 />

        {/* what_people_think_section */}
        <section className="section section_full_height centered_vertically light_grey" ref={whatPeopleThinkRef}>
          <div className="what_people_think_section section_container">
            {
              homeObjects.whatPeopleThink ?
              <Slider {...({showFullQuote, setShowFullQuote, homeObjects})} />
              :
              <div className="loading_element"><img src={refreshIcon} alt="" /></div>
            }
          </div>
          <div className="scroll_to_button lime" onClick={scrollToAboutMe}></div>
        </section>

        <SectionDivider1 />

        {/* about_me_section */}
        <section className="section section_full_height centered_vertically about_me_bg" ref={aboutMeRef}>
          <div className="bg_overlay_80"></div>
          <div className="about_me_section section_container">
            {
              homeObjects.aboutCisco ?
              <>
                {
                  homeObjects.aboutCisco.map((item, i) => (
                    <span key={i}>
                      <h2 className="page_title">About me</h2>
                      <p className="page_title">{item.paragraphText1}</p>
                      <p className="page_title">{item.paragraphText2}</p>
                    </span>
                  ))
                }
              </>
              :
              <div className="loading_element"><img src={refreshIcon} alt="" /></div>
            }
          </div>
          <div className="scroll_to_button lime" onClick={scrollToAboutSite}></div>
        </section>

        <SectionDivider1 />

        {/* about_site_section */}
        <section className="section section_full_height centered_vertically last_section grey" ref={aboutSiteRef}>
          <div className="about_site_section section_container">
            {
              homeObjects.aboutSite ?
              <>
                {
                  homeObjects.aboutSite.map((item, i) => (
                    <span key={i}>
                      <h2 className="page_title">About this site</h2>
                      <p className="page_title">{item.paragraphText1}</p>

                      <h3>Tech stack</h3>
                      <ul className="inline_list long left">
                        {
                          item.techOverview.map((tech, j) => (
                            <li key={j} className="inline_list_item">{tech}{j !== item.techOverview.length-1 ? <span className='bullet_point'>&nbsp;&bull;&nbsp;</span>:<span className='blinking-cursor'>|</span>}</li>
                          ))
                        }
                      </ul>
                    </span>
                  ))
                }
              </>
              :
              <div className="loading_element"><img src={refreshIcon} alt="" /></div>
            }
          </div>
          <div className="scroll_to_button up" onClick={scrollToWelcome}></div>
        </section>

        <SectionDivider1 />
      </div>
    </Layout>
  )
}

export default HomeView;
