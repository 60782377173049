import React, {
  useState,
  useEffect
} from 'react';

const HomePageScrollToLinks = ( props ) => {
  // console.log('EXTERNAL ===> props', props);
  // console.log('props.scrollToAboutMe', props.scrollToAboutMe);
  const [ shrinkHeader, setShrinkHeader ] = useState(false);

  useEffect(() => {
    let isSubscribed = false;

    window.addEventListener("scroll", () => {
      if (!isSubscribed) {
        if (window.scrollY < 200) {
          setShrinkHeader(false);
        } else {
          setShrinkHeader(true);
        }
      }
    });

    return () => isSubscribed = true;

  }, [shrinkHeader]);

  return (
    <div className={`home_page_links ${shrinkHeader ? 'shrink_nav' : ''}`}>
      <ul>
        <li onClick={ props.scrollToWelcome }>Welcome</li>
        <li onClick={ props.scrollToWhatIDo }>What I do</li>
        <li onClick={ props.scrollToWhatPeopleThink }>What people think</li>
        <li onClick={ props.scrollToAboutMe }>About me</li>
        <li onClick={ props.scrollToAboutSite }>About this site</li>
      </ul>
    </div>
  )
}

export default HomePageScrollToLinks;
