import React, { useState, useEffect } from 'react';
import Layout from './partials/Layout';

const NotFound404 = () => {

  const [pageTitle, setPageTitle] = useState('');
  useEffect(() => {
    // database.ref('zhomePage').on('value', snapshot => { if (snapshot.val() !== null) { setHomeObjects({ ...snapshot.val() }); } });
    setPageTitle('Page not found.');
  }, [pageTitle]);

  return (
    <Layout title={pageTitle} description="This is the 404 page">
      <div className="sections_holder">
        <section className="section dark_grey">
          <div className="section_container">
            <h1>404 Error</h1>
            <p>The page resource you are looking for is either not available or you do not have access to it.</p>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default NotFound404;
