import React, {
  useState,
  useRef,
  useEffect
} from 'react';
// import Drawer from 'react-motion-drawer';
import CheeseburgerMenu from 'cheeseburger-menu';
import HamburgerMenu from 'react-hamburger-menu';
import { Link } from "react-router-dom";
import fbHeaderLogo from '../../assets/fb-logo-white-header.svg';
// import HomePageScrollToLinks from './HomePageScrollToLinks';
// import HomePageScrollToLinks from '../HomeView';

const NavDrawer = ( props ) => {
  // Scroll to top function
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const topRef = useRef(null);
  const scrollToTop = () => scrollToRef(topRef);

  const [ menuIsOpen, setMenuIsOpen ] = useState(false);
  const [ shrinkHeader, setShrinkHeader ] = useState(false);
  // const [ otherPages, setOtherPages ] = useState(false);
  const openMenu = () => setMenuIsOpen(true);
  const closeMenu = () => setMenuIsOpen(false);

  useEffect(() => {
    let isSubscribed = false;

    window.addEventListener("scroll", () => {
      if (!isSubscribed) {
        if (window.scrollY < 200) {
          setShrinkHeader(false);
        } else {
          setShrinkHeader(true);
        }
      }
    });

    return () => isSubscribed = true;

  }, [shrinkHeader]);

  return (
    <div className={`nav ${shrinkHeader ? 'shrink_nav' : ''}`} ref={topRef}>
      {
        window.location.pathname !== '/' ?
        <div className="logo_container show_logo">
          <div className="logo">
            <img src={fbHeaderLogo} className="fb_header_logo" alt="FB header logo"/>
          </div>
        </div>
        :
        <div className={`logo_container ${shrinkHeader ? 'show_logo' : ''}`}>
          <div className="logo">
            <img src={fbHeaderLogo} className="fb_header_logo" alt="FB header logo"/>
          </div>
        </div>
      }

      <div className="cheeseburger_container">
        <CheeseburgerMenu isOpen={menuIsOpen} closeCallback={closeMenu}>
          <div className="menu-content">
            <ul>
              <li><Link to="/" onClick={() => { closeMenu(); scrollToTop(); } }>Home</Link></li>
              {/* window.location.pathname === '/' && <li><HomePageScrollToLinks /></li> */}
              <li><Link to="/profile" onClick={() => { closeMenu(); scrollToTop(); } }>Profile</Link></li>
              {/*<li><Link to="/portfolio" onClick={() => { closeMenu(); scrollToTop(); } }>Portfolio</Link></li>*/}
              <li><Link to="/portfolio" onClick={() => { closeMenu(); scrollToTop(); } }>Portfolio</Link></li>
            </ul>
          </div>
        </CheeseburgerMenu>

        <HamburgerMenu
          className="cheeseburger"
          isOpen={menuIsOpen}
          menuClicked={openMenu}
          width={32}
          height={24}
          strokeWidth={4}
          rotate={0}
          color='rgba(191, 255, 0, 0.8)'
          borderRadius={0}
          animationDuration={0.5}
        />
      </div>
    </div>
  )
}

export default NavDrawer;
