import React, {
  useState,
  useEffect
} from 'react';
import {database} from "../../store/base";

const Footer = () => {
  const [footerObjects, setFooterObjects] = useState([]);
  const [date , setDate] = useState();
  const getYear = () =>  setDate(new Date().getFullYear());

  //Once components load complete
  useEffect(() => {
    database.ref('footerArea').on('value', snapshot => { if (snapshot.val() !== null) { setFooterObjects({ ...snapshot.val() }); } });
    getYear();
  }, []);

  return (
    <footer className='footer'>
      <div>{footerObjects.role}</div>
      <div>&copy; {date} {footerObjects.copyrightText}</div>
      <div>{footerObjects.allRightsReserved}</div>
    </footer>
  );
}

export default Footer;
