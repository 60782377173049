import React, {
  useState,
  useEffect,
  useRef
} from 'react';
// import { Link } from "react-router-dom";
import {database} from "../../store/base";
// import SectionDivider1 from './partials/SectionDivider1';
import Layout from '../partials/Layout';
import refreshIcon from '../../assets/refresh-icon.svg';

import {
  ResponsiveContainer, Legend, Tooltip,
  PieChart, Pie,
  Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis,
  BarChart, Bar, XAxis, YAxis, CartesianGrid,
} from 'recharts';

const InterfaceCard = ({ card }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [showTechUsed, setShowTechUsed] = useState(false);

  return (
    <div className="scene scene--card">
      <div className={`card ${isFlipped ? 'is-flipped' : ''}`}>
        <div className="card__face card__face--front">
          {
            !isFlipped &&
            <>
              <div className="card_content">
                <div className="client_name">
                { card.frontPanel.client && <div className="bold" dangerouslySetInnerHTML={{ __html: card.frontPanel.client }}></div> }
                { card.frontPanel.name &&   <div className="" dangerouslySetInnerHTML={{ __html: card.frontPanel.name }}></div> }
                </div>
                <div className="portfolio_grid_image_container"><img src={card.frontPanel.imageUrl} alt={card.frontPanel.name} /></div>
              </div>
              <div className="flip_button" onClick={() => setIsFlipped(!isFlipped)}>&#8618; Flip card</div>
            </>
          }
        </div>

        <div className="card__face card__face--back">
          <div className="card_content">
            {
              !showTechUsed &&
              <>
                <div className="client_name" dangerouslySetInnerHTML={{ __html: card.backPanel.client }}></div>
                <div className="project_name"dangerouslySetInnerHTML={{ __html: card.backPanel.name }}></div>
                <div className="web_url"><a href={card.backPanel.webUrl} target="_blank" rel="noopener noreferrer">{card.backPanel.webFriendlyUrl}</a></div>
                <div className=""dangerouslySetInnerHTML={{ __html: card.backPanel.date }}></div>
                <div className=""dangerouslySetInnerHTML={{ __html: card.backPanel.jobTitle }}></div>
              </>
            }
            {
              card.backPanel.technologiesUsed &&
              <>
                { !showTechUsed && <button className="btn btn_plain" onClick={() => setShowTechUsed(!showTechUsed)}>Technolgies used</button> }
                {
                  showTechUsed &&
                  <>
                    <div className="close_technologies_used" onClick={() => setShowTechUsed(!showTechUsed)}>&#10005;</div>
                    <ul className="technologies_used">
                      {
                        card.backPanel.technologiesUsed.map((tech, i) => (
                          <li key={i} dangerouslySetInnerHTML={{ __html: tech }}></li>
                        ))
                      }
                    </ul>
                  </>
                }
              </>
            }
          </div>
          <div className="flip_button" onClick={() => setIsFlipped(!isFlipped)}>&#8617; Back</div>
        </div>
      </div>
    </div>
  )
}

const ChartVisualization = ({ chart }) => {
  const data = chart;
  console.log('data', data);

  return (
    <>
    {
      data.chartType === "PieChart" &&
      <>
        {
          data.chartStyle === "Donut" &&
          <div className="chart_container">
            <div className="chart_label">{data.chartLabel}</div>
            <PieChart width={350} height={400}>
              <Legend />
              <Tooltip />
              <Pie data={data.chartData} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={100} fill="#82ca9d" label />
            </PieChart>
          </div>
        }

        {
          data.chartStyle === "Pie" &&
          <div className="chart_container">
            <div className="chart_label">{data.chartLabel}</div>
            <PieChart width={350} height={400}>
              <Pie data={data.chartData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={100} fill="#8884d8" label />
              <Legend />
              <Tooltip />
            </PieChart>
          </div>
        }
      </>
    }

    {
      data.chartType === "Radar" &&
      <div className="chart_container">
        <div className="chart_label">{data.chartLabel}</div>
        <RadarChart cx="50%" cy="50%" outerRadius={100} width={320} height={400} data={data.chartData}>
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" />
          <PolarRadiusAxis angle={30} domain={[0, 100]} />
          <Radar name="Massimo" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.4} />
          <Radar name="Folarin" dataKey="B" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.4} />
          <Legend />
          <Tooltip />
        </RadarChart>
      </div>
    }

    {
      data.chartType === "Bar" &&
      <div className="chart_container">
        <div className="chart_label">{data.chartLabel}</div>
        <BarChart width={350} height={400} data={data.chartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }} >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="First-time-buyers" fill="#8884d8" />
          <Bar dataKey="Second-time-buyers" fill="#82ca9d" />
        </BarChart>
      </div>
    }
    </>
  )
}

const PortfolioView = () => {
  const [pageTitle, setPageTitle] = useState('');
  const [portfolioObjects, setPortfolioObjects] = useState([]);

  useEffect(() => {
    database.ref('zportfolioPage').on('value', snapshot => { if (snapshot.val() !== null) { setPortfolioObjects({ ...snapshot.val() }); } });
    setPageTitle('Portfolio');
  }, [pageTitle]);

  // const handleClick = (e) => {
  //   e.preventDefault();
  //   // this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  //   setIsFlipped(isFlipped => !isFlipped);
  // }

  // console.log('portfolioObjects', portfolioObjects);
  // if (portfolioObjects.portfolioIntro) console.log('portfolioObjects.portfolioIntro', portfolioObjects.portfolioIntro);
  // if (portfolioObjects.userInterfaces) console.log('portfolioObjects.userInterfaces', portfolioObjects.userInterfaces);
  // if (portfolioObjects.chartVisualizations) console.log('portfolioObjects.chartVisualizations', portfolioObjects.chartVisualizations);

  // General scroll to element function
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const portfolioIntroRef = useRef(null);
  const scrollToPortfolioIntro = () => scrollToRef(portfolioIntroRef);

  const chartVisualizationRef = useRef(null);
  const scrollToChartVisualization = () => scrollToRef(chartVisualizationRef);

  // const githubRef = useRef(null);
  // const scrollToGithub = () => scrollToRef(githubRef);

  return (
    <Layout title={pageTitle} description={`This is the ${pageTitle} page`}>
      <div className="sections_holder">

        {/* portfolioIntro */}
        <section className="section white" ref={portfolioIntroRef}>
          <div className="section_container plain_section top1 no_bottom1">
            <h1>{pageTitle}</h1>
            { portfolioObjects.portfolioIntro ?
              <>
                {
                  portfolioObjects.portfolioIntro.map((portfolioIntro, i) => (
                  <p key={i} dangerouslySetInnerHTML={{ __html: portfolioIntro }}></p>
                  ))
                }
              </>
              :
              <div className="loading_element"><img src={refreshIcon} alt="" /></div>
            }
          </div>
        </section>

        {/* User interfaces */}
        <section className="section grey">
          <div className="section_container plain_section top2 no_bottom0">
            <h2>USER INTERFACES</h2>
            {
              portfolioObjects.userInterfaces
              ?
              <div className="grid">
              {
                portfolioObjects.userInterfaces.map((card, i) => (
                  <InterfaceCard key={i} card={card} />
                ))
              }
              </div>
              :
              <div className="loading_element"><img src={refreshIcon} alt="" /></div>
            }
            <div className="loading_anim"></div>
          </div>
        </section>
        <section className="grey">
          <div className="section_container white plain_section top3 no_bottom3"></div>
          <div className="scroll_to_button true_center" onClick={scrollToChartVisualization}></div>
        </section>

        {/* <SectionDivider1 /> */}

        {/* Chart visualizations */}
        <section className="section light_grey" ref={chartVisualizationRef}>
          <div className="section_container">
            <h2>Chart visualizations</h2>
            {
              portfolioObjects.chartVisualizations ?
              <div className="grid visualizations">
                {
                  portfolioObjects.chartVisualizations.map((chart, i) => (
                    <ResponsiveContainer width="100%" height="100%" key={i}>
                      <ChartVisualization chart={chart} />
                    </ResponsiveContainer>
                  ))
                }
              </div>
              :
              <div className="loading_element"><img src={refreshIcon} alt="" /></div>
            }
          </div>
          {/*<div className="scroll_to_button true_center" onClick={scrollToGithub}></div>*/}
          <div className="scroll_to_button true_center up lime" onClick={scrollToPortfolioIntro}></div>
        </section>

        {/* <SectionDivider1 /> */}

        {/* Github */}
        {/*
        <section className="section white" ref={githubRef}>
          <div className="section_container">
            <h2>Github repositories</h2>
            <p>Blah blah blah</p>
          </div>
          <div className="scroll_to_button true_center up" onClick={scrollToPortfolioIntro}></div>
        </section>

        <SectionDivider1 />
        */}
      </div>
    </Layout>
  )
}

export default PortfolioView;
