import React from 'react';
import { Helmet } from 'react-helmet-async';
// import Navigation from './Navigation';
import NavDrawer from './navDrawer';
import Footer from './Footer';
// import 'bootswatch/dist/lux/bootstrap.css';

const Layout = ( { title, description, children } ) => {

  return (
    <>
      <Helmet>
        <title>{ title ? "Francisco Benedict - " + title : "UI • React • Firebase" }</title>
        <meta name = "description" content={ description || "Front End Developer Specialist" } />
      </Helmet>

      <div className="content">
        <NavDrawer/>
        <main className="">
          {children}
        </main>
      </div>
      <Footer />
    </>
  );
}

export default Layout;
