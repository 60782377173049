import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import {database} from "../store/base";
// import SectionDivider1 from './partials/SectionDivider1';
import Layout from './partials/Layout';
import refreshIcon from '../assets/refresh-icon.svg';

const CareerItem = ({ careerItem }) => {
  const [isFullCareerDetails, setIsFullCareerDetails] = useState(false);
  const toggleDetail = () => setIsFullCareerDetails(prevState => !prevState);

  return (
    <>
      <div className="toggle_career_detail" onClick={toggleDetail}>{ isFullCareerDetails ? '-' : '+'}</div>
      <div className="section group">
        <div className="col span_1_of_2">
          <p className="bold p_key" dangerouslySetInnerHTML={{ __html: careerItem.dateWorked }}></p>
        </div>
        <div className="col span_1_of_2">
          <p className="bold p_key" dangerouslySetInnerHTML={{ __html: careerItem.clientName }}></p>
        </div>
      </div>

      <div className="section group">
        <div className="col span_1_of_2">
          <p className="bold p_key">Position:</p>
        </div>
        <div className="col span_1_of_2">
          <p className="p_key" dangerouslySetInnerHTML={{ __html: careerItem.jobTitle }}></p>
        </div>
      </div>

      {
        isFullCareerDetails &&
        <div className="career_summary_remaining_detail">
          {/* Description */}
          <div className="section group">
            <div className="col span_1_of_2">
              <p className="bold p_key">Description:</p>
            </div>
            <div className="col span_1_of_2">
              {
                careerItem.description &&
                <>
                  {
                    careerItem.description.map((description, i) => (
                      <p key={i} className="p_key" dangerouslySetInnerHTML={{ __html: description }}></p>
                    ))
                  }
                </>
              }
            </div>
          </div>

          {/* Responsibilities & involvement */}
          <p className="bold p_key">Responsibilities & involvement</p>
          <p className="p_key" dangerouslySetInnerHTML={{ __html: careerItem.responsibilitiesAndInvolvementTxt }}></p>
          <ul>
            {
              careerItem.responsibilitiesAndInvolvementList &&
              <>
                {
                  careerItem.responsibilitiesAndInvolvementList.map((responsibilityAndInvolvement, i) => (
                    <li key={i}>{responsibilityAndInvolvement}</li>
                  ))
                }
              </>
            }
          </ul>
        </div>
      }
    </>
  )
}

const ProfileView = () => {
  const [pageTitle, setPageTitle] = useState('');
  const [profileObjects, setProfileObjects] = useState([]);

  useEffect(() => {
    database.ref('zprofilePage').on('value', snapshot => { if (snapshot.val() !== null) { setProfileObjects({ ...snapshot.val() }); } });
    setPageTitle('Profile');
  }, [pageTitle]);

  // console.log('profileObjects', profileObjects);
  // if (profileObjects.profileIntro) console.log('profileObjects.profileIntro', profileObjects.profileIntro);
  // if (profileObjects.careerSummary) console.log('profileObjects.careerSummary', profileObjects.careerSummary);
  // if (profileObjects.keyProfessionalSkills) console.log('profileObjects.keyProfessionalSkills', profileObjects.keyProfessionalSkills);
  // if (profileObjects.qualificationAndEducation) console.log('profileObjects.qualificationAndEducation', profileObjects.qualificationAndEducation);

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const profileRef = useRef(null);
  const scrollToProfile = () => scrollToRef(profileRef);
  const keyProfessionalSkillsRef = useRef(null);
  const scrollToKeyProfessionalSkills = () => scrollToRef(keyProfessionalSkillsRef);
  const careerSummaryRef = useRef(null);
  const scrollToCareerSummary = () => scrollToRef(careerSummaryRef);
  const qualificationAndEducationRef = useRef(null);
  const scrollToQualificationAndEducation = () => scrollToRef(qualificationAndEducationRef);

  return (
    <Layout title={pageTitle} description={`This is the ${pageTitle} page`}>
      <div className="sections_holder">

        {/* profileIntro */}
        <section className="section white" ref={profileRef}>
          <div className="section_container plain_section top1 no_bottom1">
            <h1>{pageTitle}</h1>
            {
              profileObjects.profileIntro ?
              <>
                {profileObjects.profileIntro.map((introItem, i) => (
                  <p key={i} dangerouslySetInnerHTML={{ __html: introItem }}></p>
                ))}
              </>
              :
              <div className="loading_element"><img src={refreshIcon} alt="" /></div>
            }
          </div>
        </section>
        <section>
          <div className="section_container white plain_section top3 no_bottom3"></div>
          <div className="scroll_to_button true_center" onClick={scrollToKeyProfessionalSkills}></div>
        </section>

        {/* keyProfessionalSkills */}
        <section className="section light_grey" ref={keyProfessionalSkillsRef}>
          <div className="section_container">
            <h2>KEY PROFESSIONAL SKILLS</h2>
            {
              profileObjects.keyProfessionalSkills ?
              <div className="client_name">
                <h3>Development</h3>
                <ul>
                {
                  profileObjects.keyProfessionalSkills.development.map((skill, i) => (
                    <li key={i} dangerouslySetInnerHTML={{ __html: skill }}></li>
                  ))
                }
                </ul>

                <h3>Design / UX</h3>
                <ul>
                {
                  profileObjects.keyProfessionalSkills.designUX.map((skill, i) => (
                    <li key={i} dangerouslySetInnerHTML={{ __html: skill }}></li>
                  ))
                }
                </ul>

                <h3>Other skills</h3>
                <ul>
                {
                  profileObjects.keyProfessionalSkills.otherSkills.map((skill, i) => (
                    <li key={i} dangerouslySetInnerHTML={{ __html: skill }}></li>
                  ))
                }
                </ul>
              </div>
              :
              <div className="loading_element"><img src={refreshIcon} alt="" /></div>
            }
          </div>
          <div className="scroll_to_button true_center lime" onClick={scrollToCareerSummary}></div>
        </section>

        {/* <SectionDivider1 /> */}

        {/* careerSummary */}
        <section className="section white" ref={careerSummaryRef}>
          <div className="section_container plain_section no_bottom0">
            <h2>CAREER SUMMARY</h2>
          </div>
        </section>
        {
          profileObjects.careerSummary ?
          <>
          {
            profileObjects.careerSummary.map((careerItem, i) => (
              <section key={i} className="section alternating_ultra_light_grey">
                <div className="section_container plain_section top3 no_bottom2">
                  <CareerItem careerItem={careerItem} />
                </div>
              </section>
            ))
          }
          </>
          :
          <div className="loading_element"><img src={refreshIcon} alt="" /></div>
        }
        <section>
          <div className="section_container white plain_section top3 no_bottom3"></div>
          <div className="scroll_to_button true_center" onClick={scrollToQualificationAndEducation}></div>
        </section>

        {/* <SectionDivider1 /> */}

        {/* qualificationAndEducation */}
        <section className="section grey" ref={qualificationAndEducationRef}>
          <div className="section_container plain_section no_bottom0">
            <h2>QUALIFICATION & EDUCATION</h2>
          </div>
        </section>

        <section className="section grey">
          {
            profileObjects.qualificationAndEducation ?
            <>
              {
                profileObjects.qualificationAndEducation.map((qualification, i) => (
                  <section className="section alternating_grey "key={i}>
                    <div className="section_container plain_section top3 no_bottom2">

                      {
                        qualification.attendanceDate && qualification.schoolName &&
                        <div className="section group">
                          <div className="col span_1_of_2">
                            <p className="bold p_key" dangerouslySetInnerHTML={{ __html: qualification.attendanceDate }}></p>
                          </div>
                          <div className="col span_1_of_2">
                            <p className="bold p_key" dangerouslySetInnerHTML={{ __html: qualification.schoolName }}></p>
                          </div>
                        </div>
                      }

                      <div className="section group">
                        <div className="col span_1_of_2">
                          <p className="bold p_key">Course</p>
                        </div>
                        <div className="col span_1_of_2">
                          { qualification.courseOverview && <p className="p_key" dangerouslySetInnerHTML={{ __html: qualification.courseOverview }}></p> }
                          { qualification.courseDetail && <p className="p_key" dangerouslySetInnerHTML={{ __html: qualification.courseDetail }}></p> }
                        </div>
                      </div>
                    </div>
                  </section>
                ))
              }
            </>
            :
            <div className="loading_element"><img src={refreshIcon} alt="" /></div>
          }
        </section>
        <section className="section grey">
          <div className="section_container plain_section top3 no_bottom3"></div>
          <div className="scroll_to_button true_center up lime" onClick={scrollToProfile}></div>
        </section>

        {/* <SectionDivider1 /> */}
      </div>
    </Layout>
  )
}

export default ProfileView;
