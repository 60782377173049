import React from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';

const SectionDivider1 = () => {
  return (
    <section className="section_divider">
      <div className="black_overlay _40"></div>
      <ParallaxBanner
        className="clouds"
        layers={[
          {
            image: 'https://firebasestorage.googleapis.com/v0/b/fir-auth-fbsite.appspot.com/o/images%2Fparalax%2Fcloud-pattern.jpg?alt=media&token=a879a317-9475-48bd-bdc6-9f7eabd799e6',
            amount: 0.5,
            props: {
              style: {
                borderLeft: '0 solid #fefefe',
                borderRight: '1px solid #fefefe',
                position: 'absolute',
                left: '0',
                width: 'calc(20% - 1px)'
              }
            }
          },
          {
            image: 'https://firebasestorage.googleapis.com/v0/b/fir-auth-fbsite.appspot.com/o/images%2Fparalax%2Fcloud-pattern.jpg?alt=media&token=a879a317-9475-48bd-bdc6-9f7eabd799e6',
            amount: -1,
            props: {
              style: {
                borderLeft: '1px solid #fefefe',
                borderRight: '1px solid #fefefe',
                position: 'absolute',
                left: '20%',
                width: 'calc(20% - 2px)'
              }
            }
          },
          {
            image: 'https://firebasestorage.googleapis.com/v0/b/fir-auth-fbsite.appspot.com/o/images%2Fparalax%2Fcloud-pattern.jpg?alt=media&token=a879a317-9475-48bd-bdc6-9f7eabd799e6',
            amount: 1,
            props: {
              style: {
                borderLeft: '1px solid #fefefe',
                borderRight: '1px solid #fefefe',
                position: 'absolute',
                left: '40%',
                width: 'calc(20% - 2px)'
              }
            }
          },
          {
            image: 'https://firebasestorage.googleapis.com/v0/b/fir-auth-fbsite.appspot.com/o/images%2Fparalax%2Fcloud-pattern.jpg?alt=media&token=a879a317-9475-48bd-bdc6-9f7eabd799e6',
            amount: -0.5,
            props: {
              style: {
                borderLeft: '1px solid #fefefe',
                borderRight: '1px solid #fefefe',
                position: 'absolute',
                left: '60%',
                width: 'calc(20% - 2px)'
              }
            }
          },
          {
            image: 'https://firebasestorage.googleapis.com/v0/b/fir-auth-fbsite.appspot.com/o/images%2Fparalax%2Fcloud-pattern.jpg?alt=media&token=a879a317-9475-48bd-bdc6-9f7eabd799e6',
            amount: 0.2,
            props: {
              style: {
                borderLeft: '1px solid #fefefe',
                borderRight: '0 solid #fefefe',
                position: 'absolute',
                left: '80%',
                width: 'calc(20% - 1px)'
              }
            }
          }
        ]}
        style={{
            height: '300px',
        }}
        disabled={false}
        expanded={false}
        >
    </ParallaxBanner>
    </section>
   );
}

export default SectionDivider1;
