import React, {
  useState,
  useEffect,
} from 'react';
import SectionDivider1  from '../partials/SectionDivider1';
// import SectionDivider2  from '../partials/SectionDivider2';
import Layout           from '../partials/Layout';

const SiteManagementView = () => {
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    setPageTitle('Site management page');
  }, [pageTitle]);

  return (
    <Layout title={pageTitle} description="This is the Home page">
      <div className="sections_holder">
        <section className="section white">
          <h1>{pageTitle}</h1>
        </section>

        <SectionDivider1 />

        <section className="section grey">
          <h2>Edit the content on the site</h2>
          <p>Blah blah blah</p>
        </section>

        <SectionDivider1 />
      </div>
    </Layout>
  )
}

export default SiteManagementView;
